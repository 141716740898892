import React from 'react';
import { Box } from '../ds/SubatomicParticles';
import { Span } from '../ds/theme/typography/Span/Span';
import { Props } from './types';

const Footer: React.FC<Props> = () => {
  return (
    <Box width="100%" height="50px" styling="row" bg="primary.default" position="fixed" bottom="0" left="0">
      <Box styling="row">
        <Span color="white">@2020. Todos os direitos reservados ConsigaMais+</Span>
      </Box>
    </Box>
  );
};

export default Footer;
