import React, { useState } from 'react';
import { useTheme } from '../../components/ds/hooks';
import { Box } from '../../components/ds/SubatomicParticles';
import { Props } from './types';
import { Document, pdfjs, Page } from 'react-pdf';
import PageHandler from './PageHandler';
import { Instagram } from 'react-content-loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyInstagramLoader = () => <Instagram />;

const PDFHandler: React.FC<Props> = ({ base64Blob, loading }: Props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isPageHandleVisible, setIsPageHandleVisible] = useState(false);
  const theme = useTheme();
  return (
    <Box
      width={{ mobile: 1, desktop: 1 / 2 }}
      height="=70%"
      maxWidth="100%"
      border="1px solid"
      borderColor="border"
      borderRadius="3px"
      boxShadow={`0px 1px 2px 0px ${theme.colors.borderShadow}`}
      overflowY="auto"
      className="tiny-scroll-bar"
      onMouseEnter={() => setIsPageHandleVisible(true)}
      onMouseLeave={() => setIsPageHandleVisible(false)}
    >
      {loading ? (
        <MyInstagramLoader />
      ) : (
        <Box>
          {base64Blob && (
            <Document
              file={`data:application/pdf;base64,${base64Blob}`}
              onLoadError={(e) => console.log(e)}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              loading={MyInstagramLoader}
            >
              <Page pageNumber={pageNumber} scale={1} />
            </Document>
          )}
          <PageHandler
            visible={isPageHandleVisible}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            numPages={numPages}
          />
        </Box>
      )}
    </Box>
  );
};

export default PDFHandler;
