import React from 'react';
import ContentLoader from 'react-content-loader';

const TermsInterfaceLoader: React.FC = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={160}
    viewBox="0 0 300 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="16" y="21" rx="5" ry="5" width="22" height="22" />
    <rect x="48" y="23" rx="5" ry="5" width="247" height="20" />
    <rect x="20" y="73" rx="8" ry="8" width="275" height="35" />
    <rect x="48" y="137" rx="5" ry="5" width="214" height="20" />
  </ContentLoader>
);

export default TermsInterfaceLoader;
