import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { Link as LinkRouter } from 'react-router-dom';
import { defaults } from '../../SubatomicParticles';
import { LinkProps } from './types';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      textDecoration: 'underline',
      fontFamily: 'OpenSans Regular',
      color: 'primary.default',
      transition: '0.3s ease-in-out all',
      cursor: 'pointer',
      '&:hover': {
        color: 'primary.hover',
      },
    },
  },
} as VariantArgs);

export default styled(LinkRouter)<LinkProps>`
  ${variants}
  ${defaults}
    ${(props) => props.css ?? ''}
`;
