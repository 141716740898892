/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDocumentMetadata
 */
export interface CreateDocumentMetadata {
    /**
     * CNPJ da MoneyPlus – Cedente da operação
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    originadorId: string;
    /**
     * CNPJ da Assina Certo - Assina e blinda o documento
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    fornecedorId: string;
    /**
     * CPF do cliente
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    debtorId: string;
    /**
     * Número da Operação
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    nrCcb: string;
    /**
     * Valor Principal
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    vlrCcb: string;
    /**
     * Nome do arquivo de áudio somente quando for fonado
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    arqAudio: string;
    /**
     * Número de telefone usado para assinatura
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    nrTelefone: string;
    /**
     * Hash do documento
     * @type {string}
     * @memberof CreateDocumentMetadata
     */
    hash: string;
}

export function CreateDocumentMetadataFromJSON(json: any): CreateDocumentMetadata {
    return CreateDocumentMetadataFromJSONTyped(json, false);
}

export function CreateDocumentMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originadorId': json['originador_id'],
        'fornecedorId': json['fornecedor_id'],
        'debtorId': json['debtor_id'],
        'nrCcb': json['nr_ccb'],
        'vlrCcb': json['vlr_ccb'],
        'arqAudio': json['arq_audio'],
        'nrTelefone': json['nr_telefone'],
        'hash': json['hash'],
    };
}

export function CreateDocumentMetadataToJSON(value?: CreateDocumentMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'originador_id': value.originadorId,
        'fornecedor_id': value.fornecedorId,
        'debtor_id': value.debtorId,
        'nr_ccb': value.nrCcb,
        'vlr_ccb': value.vlrCcb,
        'arq_audio': value.arqAudio,
        'nr_telefone': value.nrTelefone,
        'hash': value.hash,
    };
}


