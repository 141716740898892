import React, { useEffect, useState } from 'react';
import { Box } from '../../components/ds/SubatomicParticles';
import Header from '../../components/Header';
import PDFHandler from '../../components/PDFHandler';
import { Props } from './types';
import AgreementCheck from './AgreementCheck';
import SubmitButton from '../../components/SubmitButton';
import HiperLink from '../../components/HiperLink';
import Swal from 'sweetalert2';
import './tinyScrollbar.css';
import { getDocument, getOTPToken } from './actions';

import { Document as DocumentType } from '../../state';
import { useParams, useHistory } from 'react-router-dom';
import { detectTouchDevice } from '../../utils';
import TermsInterfaceLoader from './Loader';
import Footer from '../../components/Footer';
import withReactContent from 'sweetalert2-react-content';
import Loading from '../../components/Loading';

const Terms: React.FC<Props> = () => {
  const history = useHistory();
  const sAlert = withReactContent(Swal);

  const emptyDocument: DocumentType = {
    title: '',
    blob: '',
    phone: '',
  };

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [document, setDocument] = useState<DocumentType>(emptyDocument);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const toggleIsAgreementChecked = () => {
    setIsAgreementChecked((prevState) => !prevState);
    toggleIsSubmitButtonDisabled();
  };
  const toggleIsSubmitButtonDisabled = () => setIsSubmitButtonDisabled((prevState) => !prevState);
  const { shortUrl } = useParams<{ shortUrl: string | undefined }>();

  const goToSign = async () => {
    const path = detectTouchDevice() ? 'sign' : 'tokenSign';
    if (path === 'tokenSign') {
      try {
        if (shortUrl) {
          setIsRequestLoading(true);
          await getOTPToken({ shortUrl: { shortUrl } });
          setIsRequestLoading(false);

          history.push(`/${shortUrl}/${path}`, { phone: document.phone });
        }
      } catch (e) {
        console.log(e);
        setIsRequestLoading(false);

        sAlert.fire({
          icon: 'error',
          title: 'Ops...',
          text: e.message ? e.message : 'Algo deu errado',
        });
      }
    } else {
      history.push(`/${shortUrl}/${path}`);
    }
  };

  useEffect(() => {
    const loadDocument = async () => {
      setIsDocumentLoading(true);
      if (shortUrl) {
        try {
          const loadedDocument = await getDocument({ shortUrl });
          setDocument(loadedDocument);
          setIsDocumentLoading(false);
        } catch (e) {
          if (e.status === 404) {
            history.push('/documents/notFound');
          } else if (e.status === 406) {
            history.push('/documents/expired');
          }
        }
      }
    };
    loadDocument();
  }, []);
  return (
    <>
      {isRequestLoading && <Loading />}
      <Header />
      <Box styling="row" justifyContent="center" my="10px" mx="10px">
        <PDFHandler loading={isDocumentLoading} base64Blob={document.blob} />
      </Box>
      {isDocumentLoading ? (
        <Box justifyContent="center" styling="row">
          <TermsInterfaceLoader />
        </Box>
      ) : (
        <Box>
          <Box styling="row" justifyContent="center" my="10px">
            <AgreementCheck checked={isAgreementChecked} onClick={toggleIsAgreementChecked} />
          </Box>
          <Box styling="row" justifyContent="center" my="10px">
            <SubmitButton disabled={isSubmitButtonDisabled} text="CLIQUE PARA ASSINAR" onClick={goToSign} />
          </Box>
          <Box styling="row" justifyContent="center" mt="20px" mb="30px">
            <HiperLink text="quero desistir" to="/documents/thanks" />
          </Box>
        </Box>
      )}
      <Footer />
    </>
  );
};

export default Terms;
