import React from 'react';
import { Box } from '../../../components/ds/SubatomicParticles';
import { H1 } from '../../../components/ds/theme/typography';
import Header from '../../../components/Header';
import { Props } from './types';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/working.json';
import Footer from '../../../components/Footer';

const Expired: React.FC<Props> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <Header />

      <Box styling="row" mt="4vh">
        <H1 color="primary.default" styling="base">
          Documento expirado.
        </H1>
      </Box>
      <Box styling="row" mt="4vh">
        <Lottie options={defaultOptions} height={300} width={300} isStopped={false} isPaused={false} />
      </Box>
      <Footer />
    </>
  );
};

export default Expired;
