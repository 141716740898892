/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateDocumentMetadata,
    CreateDocumentMetadataFromJSON,
    CreateDocumentMetadataFromJSONTyped,
    CreateDocumentMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateDocument
 */
export interface CreateDocument {
    /**
     * Título do documento.
     * @type {string}
     * @memberof CreateDocument
     */
    title: string;
    /**
     * Telefone relacionado ao documento.
     * @type {string}
     * @memberof CreateDocument
     */
    phone: string;
    /**
     * Nome do PDF do documento no Azure.
     * @type {string}
     * @memberof CreateDocument
     */
    azureDocumentId: string;
    /**
     * Nome do container do Azure no qual o PDF do documento está.
     * @type {string}
     * @memberof CreateDocument
     */
    azureContainerId: string;
    /**
     * Número que representa o tamanho do tempo de expiração do documento. Complementa o campo duration. Se o duration for '3' e o dirationType for 'days', o documento valerá por 3 dias.
     * @type {number}
     * @memberof CreateDocument
     */
    duration: number;
    /**
     * Tipo da duração do tempo de expiração do documento, complementa o campo duration. Se o duration for '3' e o dirationType for 'days', o documento valerá por 3 dias.
     * @type {string}
     * @memberof CreateDocument
     */
    durationType: CreateDocumentDurationTypeEnum;
    /**
     * 
     * @type {CreateDocumentMetadata}
     * @memberof CreateDocument
     */
    createDocumentMetadata: CreateDocumentMetadata;
}

/**
* @export
* @enum {string}
*/
export enum CreateDocumentDurationTypeEnum {
    Years = 'years',
    Months = 'months',
    Weeks = 'weeks',
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes',
    Seconds = 'seconds'
}

export function CreateDocumentFromJSON(json: any): CreateDocument {
    return CreateDocumentFromJSONTyped(json, false);
}

export function CreateDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'phone': json['phone'],
        'azureDocumentId': json['azureDocumentId'],
        'azureContainerId': json['azureContainerId'],
        'duration': json['duration'],
        'durationType': json['durationType'],
        'createDocumentMetadata': CreateDocumentMetadataFromJSON(json['createDocumentMetadata']),
    };
}

export function CreateDocumentToJSON(value?: CreateDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'phone': value.phone,
        'azureDocumentId': value.azureDocumentId,
        'azureContainerId': value.azureContainerId,
        'duration': value.duration,
        'durationType': value.durationType,
        'createDocumentMetadata': CreateDocumentMetadataToJSON(value.createDocumentMetadata),
    };
}


