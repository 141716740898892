/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemainingTime
 */
export interface RemainingTime {
    /**
     * Tempo em segundos mostrando por quantos segundos o último token gerado ainda pode ser utilizado
     * @type {string}
     * @memberof RemainingTime
     */
    remainingTime: string;
}

export function RemainingTimeFromJSON(json: any): RemainingTime {
    return RemainingTimeFromJSONTyped(json, false);
}

export function RemainingTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemainingTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'remainingTime': json['remainingTime'],
    };
}

export function RemainingTimeToJSON(value?: RemainingTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remainingTime': value.remainingTime,
    };
}


