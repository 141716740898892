/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignDocumentMetadata,
    SignDocumentMetadataFromJSON,
    SignDocumentMetadataFromJSONTyped,
    SignDocumentMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface OTPTokenSign
 */
export interface OTPTokenSign {
    /**
     * Código de 6 dígitos que é enviado ao celular do usuário
     * @type {string}
     * @memberof OTPTokenSign
     */
    otpToken: string;
    /**
     * Ao criar um documento é gerada uma URL única com um prazo de expiração. Esta url é passada com parâmetro para interagir com o documento
     * @type {string}
     * @memberof OTPTokenSign
     */
    shortUrl: string;
    /**
     * 
     * @type {SignDocumentMetadata}
     * @memberof OTPTokenSign
     */
    signDocumentMetadata: SignDocumentMetadata;
}

export function OTPTokenSignFromJSON(json: any): OTPTokenSign {
    return OTPTokenSignFromJSONTyped(json, false);
}

export function OTPTokenSignFromJSONTyped(json: any, ignoreDiscriminator: boolean): OTPTokenSign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'otpToken': json['otpToken'],
        'shortUrl': json['shortUrl'],
        'signDocumentMetadata': SignDocumentMetadataFromJSON(json['signDocumentMetadata']),
    };
}

export function OTPTokenSignToJSON(value?: OTPTokenSign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'otpToken': value.otpToken,
        'shortUrl': value.shortUrl,
        'signDocumentMetadata': SignDocumentMetadataToJSON(value.signDocumentMetadata),
    };
}


