import {
  SignDocumentApi,
  Configuration,
  SignDocumentWithHandmadeSignRequest,
  SignDocumentWithTokenRequest,
} from '../../state/';
import { getApiPath } from '../../utils';

const documentApiConfiguration = new Configuration({
  basePath: getApiPath(),
});
const signDocumentClient = new SignDocumentApi(documentApiConfiguration);

export const validateOTPAndSignDocument = async (requestParameters: SignDocumentWithTokenRequest): Promise<boolean> => {
  await signDocumentClient.signDocumentWithToken(requestParameters);

  return true;
};

export const signDocumentWithHandmadeSign = async (
  requestParameters: SignDocumentWithHandmadeSignRequest,
): Promise<boolean> => {
  await signDocumentClient.signDocumentWithHandmadeSign(requestParameters);
  return true;
};
