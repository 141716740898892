import React from 'react';
import { Box } from '../ds/SubatomicParticles';
import { H1 } from '../ds/theme/typography';
import StepNumber from './StepNumber';
import { Props } from './types';

const Header: React.FC<Props> = () => {
  return (
    <Box width="100%" height="50px" styling="row" bg="primary.default">
      <Box styling="row">
        <Box mr="10px">
          <StepNumber value="3" />
        </Box>
        <H1 color="white" styling="base">
          Cadastro e Assinatura
        </H1>
      </Box>
    </Box>
  );
};

export default Header;
