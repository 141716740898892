import React from 'react';
import styled from 'styled-components';
import { Box } from '../ds/SubatomicParticles';

const Dots = styled.div`
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #265672;
  color: #265672;
  box-shadow: 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672, 10007px 0 0 0 #265672;
  animation: dotBricks 2s infinite ease;

  @keyframes dotBricks {
    0% {
      box-shadow: 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672, 10007px 0 0 0 #265672;
    }
    8.333% {
      box-shadow: 10007px -16px 0 0 #265672, 9991px 0 0 0 #265672, 10007px 0 0 0 #265672;
    }
    16.667% {
      box-shadow: 10007px -16px 0 0 #265672, 9991px -16px 0 0 #265672, 10007px 0 0 0 #265672;
    }
    25% {
      box-shadow: 10007px -16px 0 0 #265672, 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672;
    }
    33.333% {
      box-shadow: 10007px 0 0 0 #265672, 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672;
    }
    41.667% {
      box-shadow: 10007px 0 0 0 #265672, 10007px -16px 0 0 #265672, 9991px 0 0 0 #265672;
    }
    50% {
      box-shadow: 10007px 0 0 0 #265672, 10007px -16px 0 0 #265672, 9991px -16px 0 0 #265672;
    }
    58.333% {
      box-shadow: 9991px 0 0 0 #265672, 10007px -16px 0 0 #265672, 9991px -16px 0 0 #265672;
    }
    66.666% {
      box-shadow: 9991px 0 0 0 #265672, 10007px 0 0 0 #265672, 9991px -16px 0 0 #265672;
    }
    75% {
      box-shadow: 9991px 0 0 0 #265672, 10007px 0 0 0 #265672, 10007px -16px 0 0 #265672;
    }
    83.333% {
      box-shadow: 9991px -16px 0 0 #265672, 10007px 0 0 0 #265672, 10007px -16px 0 0 #265672;
    }
    91.667% {
      box-shadow: 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672, 10007px -16px 0 0 #265672;
    }
    100% {
      box-shadow: 9991px -16px 0 0 #265672, 9991px 0 0 0 #265672, 10007px 0 0 0 #265672;
    }
  }
`;

const Loading: React.FC = () => {
  return (
    <Box position="absolute" height="100vh" width="100vw" backgroundColor="overlay" zIndex={1}>
      <Box position="absolute" height="100px" width="100px" top="50%" left="50%">
        <Dots />
      </Box>
    </Box>
  );
};
export default Loading;
