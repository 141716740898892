import React, { useEffect, useState } from 'react';
import { Box } from '../../components/ds/SubatomicParticles';
import { H1 } from '../../components/ds/theme/typography';

import { Span } from '../../components/ds/theme/typography/Span/Span';
import Header from '../../components/Header';
import SubmitButton from '../../components/SubmitButton';
import OtpInput from './OtpInput';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Props } from './types';
import { useParams, useHistory } from 'react-router-dom';
import { ShortUrlObject } from '../../state';
import { validateOTPAndSignDocument } from './actions';
import { GeolocatedProps, geolocated } from 'react-geolocated';

import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import { formatPhone } from '../../utils';

const TokenSign: React.FC<Props> = ({
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
}: Props & GeolocatedProps) => {
  const { shortUrl } = useParams<ShortUrlObject>();
  const history = useHistory<{ phone: string }>();
  const [token, setToken] = useState('');
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    token.length >= 6 ? setIsSubmitButtonDisabled(false) : setIsSubmitButtonDisabled(true);
  }, [token]);
  const sAlert = withReactContent(Swal);

  const onSubmit = async () => {
    try {
      if (!isGeolocationAvailable || !isGeolocationEnabled) {
        throw new Error('Por favor, permita a utilzação de sua geolocalização');
      }
      const signDocumentMetadata = {
        dthrAssinatura: new Date(),
        geolocalizacao: JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude }),
        documentType: 'PJ',
      };
      setIsLoading(true);

      await validateOTPAndSignDocument({ otpTokenSign: { otpToken: token, shortUrl, signDocumentMetadata } });
      console.log('validated and signed');
      setIsLoading(false);

      sAlert
        .fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Documento assinado com sucesso!',
        })
        .then(() => {
          history.push('/documents/thanks');
        });
    } catch (e) {
      setIsLoading(false);

      sAlert.fire({
        icon: 'error',
        title: 'Ops...',
        text: e.message ? e.message : 'Algo deu errado',
      });
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <Box styling="row" mt="20px">
        <H1 color="primary.default" styling="base">
          Digite o Token
        </H1>
      </Box>
      <Box styling="column" justifyContent="center" mt="20px">
        <Box styling="row" justifyContent="center">
          <Span fontFamily="OpenSans Light" fontSize="18px">
            {history.location.state.phone && formatPhone(history.location.state.phone)}
          </Span>
        </Box>
        <Box mt="20px" styling="row" justifyContent="center">
          <Span fontFamily="OpenSans Regular" textAlign="center">
            Enviamos o código via mensagem de texto para seu dispositivo.
            <br /> Por favor, insira o código abaixo.
          </Span>
        </Box>
      </Box>
      <Box styling="row" justifyContent="center" mt="20px">
        <OtpInput value={token} onChange={setToken} />
      </Box>
      <Box styling="row" justifyContent="center" mt="20px">
        <SubmitButton disabled={isSubmitButtonDisabled} text="ENVIAR" onClick={onSubmit} />
      </Box>
      <Footer />
    </>
  );
};

export default geolocated()(TokenSign);
