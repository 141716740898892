import React, { useRef, useEffect, useState } from 'react';
import { Props } from './types';
import SignatureCanvas from 'react-signature-canvas';
import { Box } from '../../../components/ds/SubatomicParticles';
import theme from '../../../components/ds/theme';

const HEIGHT = '135px';
const SignCanvas: React.FC<Props> = ({ canvasRef }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, containerRef.current?.offsetWidth]);

  return (
    <Box width={{ mobile: 1, desktop: 1 / 3 }} height={HEIGHT} styling="row" justifyContent="center">
      <Box
        width="calc(100% - 25px)"
        height="100%"
        ref={containerRef}
        borderBottom={`1px solid ${theme.colors.signLine}`}
      >
        <SignatureCanvas
          ref={canvasRef}
          penColor={theme.colors.primary.default}
          canvasProps={{ width: containerWidth, height: HEIGHT, className: 'sigCanvas' }}
        />
      </Box>
    </Box>
  );
};

export default SignCanvas;
