import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
// import PDFHandler from '../../components/PDFHandler';
import { Box } from '../../components/ds/SubatomicParticles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import SubmitButton from '../../components/SubmitButton';

import { getDocument } from '../Terms/actions';

const SignedDocument = () => {
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { shortUrl } = useParams();

  const loadDocument = async () => {
    setIsLoading(true);
    if (shortUrl) {
      try {
        const loadedDocument = await getDocument({ shortUrl });
        return loadedDocument;
      } catch (e) {
        if (e.status === 404) {
          <Redirect to="/documents/notFound" />;
        } else if (e.status === 406) {
          <Redirect to="/documents/expired" />;
        }
      }
    }
  };

  const getAuthorizationToken = async () => {
    const authorizationToken = await axios.post(
      `${process.env.REACT_APP_API_CONSIGA}/user/authenticate`,
      {
        username: 'Android',
        password: 'apiAndroid@2020',
      },
      {
        'Content-Type': 'application/json',
      },
    );
    return authorizationToken.data.auth_token;
  };

  const getAzureDocumentUrl = async (document, authorizationToken) => {
    if (!!document) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_CONSIGA}/assinatura/getCCBPJAssinada/${document.title}`,
          {
            headers: {
              Authorization: `Bearer ${authorizationToken}`,
            },
          },
        );
        setData(result.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const redireciona = () => (window.location.href = '/documents/thanks');

  useEffect(async () => {
    const document = await loadDocument();
    const authorizationToken = await getAuthorizationToken();
    getAzureDocumentUrl(document, authorizationToken);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      {console.log(data.url)}
      {data && (
        <>
          <Box styling="column" height="calc(100vh - 142px)">
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              src={`https://docs.google.com/gview?url=${data.url}&embedded=true`}
            ></iframe>
          </Box>
          <Box styling="row" justifyContent="center" my="20px">
            <SubmitButton text="Continuar" onClick={redireciona} />
          </Box>
        </>
      )}

      {/* {data && (
        <>
          {console.log(data.url)}
          <Box styling="row" justifyContent="center" my="20px">
            <PDFHandler base64Blob={data.file.document} />
          </Box>
          <Box styling="row" justifyContent="center" my="50px">
            <SubmitButton text="Continuar" onClick={redireciona} />
          </Box>
          <Box styling="row" justifyContent="center" my="50px"></Box>
        </>
      )} */}
      {/* <Footer /> */}
    </>
  );
};

export default SignedDocument;
