/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignDocumentMetadata
 */
export interface SignDocumentMetadata {
    /**
     * Data e hora da assinatura (padrão ISO 8601)
     * @type {Date}
     * @memberof SignDocumentMetadata
     */
    dthrAssinatura: Date;
    /**
     * Informaçãos sobra a geolocalização do dispositivo que está enviando a assinatura
     * @type {string}
     * @memberof SignDocumentMetadata
     */
    geolocalizacao: string;
    /**
     * Tipo do documento
     * @type {string}
     * @memberof SignDocumentMetadata
     */
    documentType: string;
}

export function SignDocumentMetadataFromJSON(json: any): SignDocumentMetadata {
    return SignDocumentMetadataFromJSONTyped(json, false);
}

export function SignDocumentMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignDocumentMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { 
        'dthrAssinatura': (new Date(json['dthr_assinatura'])),
        'geolocalizacao': json['geolocalizacao'],
        'documentType': json['documentType'],
    };
}

export function SignDocumentMetadataToJSON(value?: SignDocumentMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dthr_assinatura': (value.dthrAssinatura.toISOString()),
        'geolocalizacao': value.geolocalizacao,
    };
}


