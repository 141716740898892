import React from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { defaults } from '../SubatomicParticles';
import { ButtonProps } from './index';

const buttonStyle = {
  backgroundColor: 'success.default',
  borderRadius: '40px',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  boxShadow: (props: any) => `0px 1px 1px ${props.colors.successShadow}`,
  transition: '0.3s ease-in-out all',
};

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      ...buttonStyle,
      '&:hover': {
        backgroundColor: 'success.hover',
      },
    },
    get disabled() {
      return {
        ...buttonStyle,
        opacity: '0.5',
        cursor: 'default',
      };
    },
  },
} as VariantArgs);

export const Style: React.FC<ButtonProps> = styled.button<ButtonProps>`
  ${variants}
  ${defaults}
    ${(props) => props.css ?? ''}
`;

const Button: React.FC<ButtonProps> = ({ children, onFocus, onBlur, ...props }) => {
  const [focused, set] = React.useState(false);
  const toggleState = () => set((prevState) => !prevState);
  return (
    <Style
      className={focused ? 'focused' : ''}
      onFocus={(e) => {
        toggleState();
        onFocus && onFocus(e);
      }}
      onBlur={(e) => {
        toggleState();
        onBlur && onBlur(e);
      }}
      {...props}
    >
      {children}
    </Style>
  );
};

export default Button;
