/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DefaultError,
    DefaultErrorFromJSON,
    DefaultErrorToJSON,
    OTPTokenSign,
    OTPTokenSignFromJSON,
    OTPTokenSignToJSON,
    RemainingTime,
    RemainingTimeFromJSON,
    RemainingTimeToJSON,
    ShortUrlObject,
    ShortUrlObjectFromJSON,
    ShortUrlObjectToJSON,
    UnautorizedError,
    UnautorizedErrorFromJSON,
    UnautorizedErrorToJSON,
} from '../models';

export interface GetOTPTokenRequest {
    shortUrl: ShortUrlObject;
}

export interface GetOTPTokenTimerRequest {
    shortUrl?: string;
}

export interface ValidateOTPTokenRequest {
    otpToken: OTPTokenSign;
}

/**
 * 
 */
export class OTPTokenApi extends runtime.BaseAPI {

    /**
     * Gera um token para assinutura e o envia via SMS para o telefone cadastrado no documento, cada token tem validade de 3 minutos
     * Gera e envia o token de assinatura
     */
    async getOTPTokenRaw(requestParameters: GetOTPTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shortUrl === null || requestParameters.shortUrl === undefined) {
            throw new runtime.RequiredError('shortUrl','Required parameter requestParameters.shortUrl was null or undefined when calling getOTPToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/documents/OTPToken`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShortUrlObjectToJSON(requestParameters.shortUrl),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Gera um token para assinutura e o envia via SMS para o telefone cadastrado no documento, cada token tem validade de 3 minutos
     * Gera e envia o token de assinatura
     */
    async getOTPToken(requestParameters: GetOTPTokenRequest): Promise<void> {
        await this.getOTPTokenRaw(requestParameters);
    }

    /**
     * Ao gerar um token, ele tem validade de 3 minutos. Esta rota retorna por quanto tempo  o último token gerado ainda será válido.
     * Retorna por quantos segundos o último token gerado ainda será válido.
     */
    async getOTPTokenTimerRaw(requestParameters: GetOTPTokenTimerRequest): Promise<runtime.ApiResponse<RemainingTime>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.shortUrl !== undefined && requestParameters.shortUrl !== null) {
            headerParameters['shortUrl'] = String(requestParameters.shortUrl);
        }

        const response = await this.request({
            path: `/v1/documents/OTPToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RemainingTimeFromJSON(jsonValue));
    }

    /**
     * Ao gerar um token, ele tem validade de 3 minutos. Esta rota retorna por quanto tempo  o último token gerado ainda será válido.
     * Retorna por quantos segundos o último token gerado ainda será válido.
     */
    async getOTPTokenTimer(requestParameters: GetOTPTokenTimerRequest): Promise<RemainingTime> {
        const response = await this.getOTPTokenTimerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Rota utilitária para testar se um determinado token é valido.
     * Valida um token
     */
    async validateOTPTokenRaw(requestParameters: ValidateOTPTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.otpToken === null || requestParameters.otpToken === undefined) {
            throw new runtime.RequiredError('otpToken','Required parameter requestParameters.otpToken was null or undefined when calling validateOTPToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/documents/OTPToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OTPTokenSignToJSON(requestParameters.otpToken),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rota utilitária para testar se um determinado token é valido.
     * Valida um token
     */
    async validateOTPToken(requestParameters: ValidateOTPTokenRequest): Promise<void> {
        await this.validateOTPTokenRaw(requestParameters);
    }

}
