import React from 'react';
import { css } from 'styled-components';
import { Box } from '../../../components/ds/SubatomicParticles';
import { Props } from './types';
import { ReactComponent as ArrowLeft } from '../../../assets/images/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/svg/arrow-right.svg';

const iconStyle = {
  cursor: 'pointer',
  svg: { strokeWidth: '2px', stroke: 'primary.default' },
  height: '15px',
  width: '15px',
};

const PageHandler: React.FC<Props> = ({ pageNumber, setPageNumber, numPages, visible }: Props) => {
  const clickOnLeft = () => {
    if (pageNumber >= 2) setPageNumber(pageNumber - 1);
  };
  const clickOnRight = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };
  return (
    <Box
      styling={'row'}
      position="absolute"
      top="350px"
      left="50%"
      css={css({ transform: 'translateX(-50%)' })}
      transition="0.3s ease-in-out all"
      opacity={visible ? 0.5 : 0.2}
    >
      <Box onClick={clickOnLeft} css={css(iconStyle)} mr="20px">
        <ArrowLeft />
      </Box>
      <Box onClick={clickOnRight} css={css(iconStyle)}>
        <ArrowRight />
      </Box>
    </Box>
  );
};

export default PageHandler;
