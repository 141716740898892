import React from 'react';
import { css } from 'styled-components';
import { useTheme } from '../ds/hooks';
import { Span } from '../ds/theme/typography/Span/Span';
import { StepNumberProps } from './types';

const StepNumber: React.FC<StepNumberProps> = ({ value }: StepNumberProps) => {
  const theme = useTheme();
  return (
    <Span
      css={css({
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.primary.default,
        border: `2px solid ${theme.colors.newBorder}`,
        color: 'white',
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        verticalAlign: 'middle',
        fontSize: '14px',
        fontFamily: 'OpenSans SemiBold',
        marginBottom: '10px',
        '&:after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '2px',
          height: '15px',
          top: '35px',
          backgroundColor: theme.colors.newBorder,
        },
      })}
    >
      {value}
    </Span>
  );
};

export default StepNumber;
