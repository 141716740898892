/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DefaultError,
    DefaultErrorFromJSON,
    DefaultErrorToJSON,
    Document,
    DocumentFromJSON,
    DocumentToJSON,
    HandmadeSignObject,
    HandmadeSignObjectFromJSON,
    HandmadeSignObjectToJSON,
    OTPTokenSign,
    OTPTokenSignFromJSON,
    OTPTokenSignToJSON,
} from '../models';

export interface SignDocumentWithHandmadeSignRequest {
    handmadeSign: HandmadeSignObject;
}

export interface SignDocumentWithTokenRequest {
    otpTokenSign: OTPTokenSign;
}

/**
 * 
 */
export class SignDocumentApi extends runtime.BaseAPI {

    /**
     * Caso o usuário esteja utilizando o sistema com um dispositivo com tela sensível ao toque, é possível que ele assine o documento como se ele estivesse assinando um papel.
     * Assina documento com assinatura manuscrita
     */
    async signDocumentWithHandmadeSignRaw(requestParameters: SignDocumentWithHandmadeSignRequest): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters.handmadeSign === null || requestParameters.handmadeSign === undefined) {
            throw new runtime.RequiredError('handmadeSign','Required parameter requestParameters.handmadeSign was null or undefined when calling signDocumentWithHandmadeSign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/documents/signDocumentWithHandmadeSign`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HandmadeSignObjectToJSON(requestParameters.handmadeSign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Caso o usuário esteja utilizando o sistema com um dispositivo com tela sensível ao toque, é possível que ele assine o documento como se ele estivesse assinando um papel.
     * Assina documento com assinatura manuscrita
     */
    async signDocumentWithHandmadeSign(requestParameters: SignDocumentWithHandmadeSignRequest): Promise<Document> {
        const response = await this.signDocumentWithHandmadeSignRaw(requestParameters);
        return await response.value();
    }

    /**
     * Assinar um documento utilizando um token gerado anteriormente
     * Assinar um documento com um token
     */
    async signDocumentWithTokenRaw(requestParameters: SignDocumentWithTokenRequest): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters.otpTokenSign === null || requestParameters.otpTokenSign === undefined) {
            throw new runtime.RequiredError('otpTokenSign','Required parameter requestParameters.otpTokenSign was null or undefined when calling signDocumentWithToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/documents/signDocumentWithToken`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OTPTokenSignToJSON(requestParameters.otpTokenSign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Assinar um documento utilizando um token gerado anteriormente
     * Assinar um documento com um token
     */
    async signDocumentWithToken(requestParameters: SignDocumentWithTokenRequest): Promise<Document> {
        const response = await this.signDocumentWithTokenRaw(requestParameters);
        return await response.value();
    }

}
