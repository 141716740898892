import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as theme from './components/ds/theme';

import './assets/style/fonts.css';
import { ThemeProvider } from 'styled-components';
import Terms from './containers/Terms';
import Sign from './containers/Sign';
import TokenSign from './containers/TokenSign';
import NotFound from './containers/UtilityPages/NotFound';
import Expired from './containers/UtilityPages/Expired';
import Thanks from './containers/UtilityPages/Thanks';
import SignedDocument from './containers/SignedDocument';

const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={theme.default}>
        <Switch>
          <Route exact path="/" component={NotFound} />
          <Route exact path="/documents/notFound" component={NotFound} />
          <Route exact path="/documents/expired" component={Expired} />
          <Route exact path="/documents/thanks" component={Thanks} />
          <Route exact path="/:shortUrl" component={Terms} />
          <Route exact path="/:shortUrl/sign" component={Sign} />
          <Route exact path="/:shortUrl/tokenSign" component={TokenSign} />
          <Route exact path="/:shortUrl/signedDocument" component={SignedDocument} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default App;
