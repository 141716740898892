/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateDocument,
    CreateDocumentFromJSON,
    CreateDocumentToJSON,
    DefaultError,
    DefaultErrorFromJSON,
    DefaultErrorToJSON,
    Document,
    DocumentFromJSON,
    DocumentToJSON,
} from '../models';

export interface GetDocumentRequest {
    shortUrl?: string;
}

export interface PostDocumentRequest {
    createDocument: CreateDocument;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Gets a document by short url.
     * Gets a document by short url
     */
    async getDocumentRaw(requestParameters: GetDocumentRequest): Promise<runtime.ApiResponse<Document>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.shortUrl !== undefined && requestParameters.shortUrl !== null) {
            headerParameters['shortUrl'] = String(requestParameters.shortUrl);
        }

        const response = await this.request({
            path: `/v1/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Gets a document by short url.
     * Gets a document by short url
     */
    async getDocument(requestParameters: GetDocumentRequest): Promise<Document> {
        const response = await this.getDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cria um novo documento no sistema
     * Criar um document
     */
    async postDocumentRaw(requestParameters: PostDocumentRequest): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters.createDocument === null || requestParameters.createDocument === undefined) {
            throw new runtime.RequiredError('createDocument','Required parameter requestParameters.createDocument was null or undefined when calling postDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentToJSON(requestParameters.createDocument),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Cria um novo documento no sistema
     * Criar um document
     */
    async postDocument(requestParameters: PostDocumentRequest): Promise<Document> {
        const response = await this.postDocumentRaw(requestParameters);
        return await response.value();
    }

}
