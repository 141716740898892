/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShortUrlObject
 */
export interface ShortUrlObject {
    /**
     * Ao criar um documento é gerada uma URL única com um prazo de expiração. Esta url é passada com parâmetro para interagir com o documento
     * @type {string}
     * @memberof ShortUrlObject
     */
    shortUrl: string;
}

export function ShortUrlObjectFromJSON(json: any): ShortUrlObject {
    return ShortUrlObjectFromJSONTyped(json, false);
}

export function ShortUrlObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortUrlObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortUrl': json['shortUrl'],
    };
}

export function ShortUrlObjectToJSON(value?: ShortUrlObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortUrl': value.shortUrl,
    };
}


