import {
  DocumentApi,
  OTPTokenApi,
  Configuration,
  Document,
  GetDocumentRequest,
  GetOTPTokenRequest,
} from '../../state/';
import { getApiPath } from '../../utils';

const documentApiConfiguration = new Configuration({
  basePath: getApiPath(),
});

const otpTokenApiConfiguration = new Configuration({
  basePath: getApiPath(),
});

const documentClient = new DocumentApi(documentApiConfiguration);

const otpTokenClient = new OTPTokenApi(otpTokenApiConfiguration);

export const getDocument = async (requestParameters: GetDocumentRequest): Promise<Document> => {
  return await documentClient.getDocument(requestParameters);
};

export const getOTPToken = async (requestParameters: GetOTPTokenRequest): Promise<void> => {
  return await otpTokenClient.getOTPToken(requestParameters);
};
