import React from 'react';
import Checkbox from '../../../components/ds/atoms/Checkbox';
import { P } from '../../../components/ds/theme/typography';
import { Props } from './types';

const AgreementCheck: React.FC<Props> = ({ checked, onClick }: Props) => {
  return (
    <Checkbox
      id="agreementCheckBox"
      name="agreementCheckBox"
      styling={checked ? 'base' : 'unchecked'}
      checked={checked}
      onChange={onClick}
      label={
        <P styling="base" color="primary.default">
          Li e concordo com o<strong> Termos da CCB</strong>
        </P>
      }
    />
  );
};

export default AgreementCheck;
