import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { CheckboxProps, CheckboxStyledProps } from './index';
import { defaults } from '../../SubatomicParticles';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      position: 'relative',
      padding: '12px',
      '&:focus': {
        outline: 'none',
        label: {
          '& > span': {
            backgroundColor: (props: any) => `${props.colors.contrast}10`,
          },
        },
      },
      label: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: '-11px',
        verticalAlign: 'middle',
        '& > span': {
          flex: '0 0 auto',
          color: 'primary.default',
          overflow: 'visible',
          fontSize: '1.5rem',
          textAlign: 'center',
          transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: (props: any) => `${props.colors.contrast}10`,
          },
          '& > span': {
            width: '100%',
            display: 'flex',
            alignItems: 'inherit',
            justifyContent: 'inherit',
          },
        },
      },
      input: {
        top: '0',
        left: '0',
        width: '100%',
        cursor: 'inherit',
        height: '100%',
        margin: '0',
        opacity: '0',
        padding: '0',
        zIndex: '1',
        position: 'absolute',
      },
      svg: {
        fill: '#87DBCC',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        flexShrink: '0',
        userSelect: 'none',
      },
      p: {
        fontSize: '13.5px',
        lineHeight: '18px',
        marginLeft: '5px',
      },
    },
    get unchecked() {
      return {
        ...this.base,
        svg: {
          width: '1em',
          height: '1em',
          display: 'inline-block',
          fontSize: '1.5rem',
          flexShrink: '0',
          userSelect: 'none',
          fill: 'primary.default',
        },
      };
    },
  },
} as VariantArgs);

const Style: React.FC<CheckboxStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

const checkedSvg = (
  <svg focusable="false" viewBox="0 0 22 22" aria-hidden="true" role="presentation">
    <rect width="22" height="22" rx="4" fill="#87DBCC" />
    <path d="M7 11L10 14L16 8" stroke="#8E8989" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
const uncheckedSvg = (
  <svg focusable="false" viewBox="0 0 22 22" aria-hidden="true" role="presentation">
    <path d={'M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'} />
  </svg>
);

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const svg = useMemo(
    () => (props.checked ? checkedSvg : uncheckedSvg),

    [props.checked],
  );

  return (
    <Style styling={props.styling ?? 'base'} style={{ ...props.style, color: props?.color }}>
      <label className={props.checked ? 'checked' : ''} htmlFor={props.id}>
        <span>
          <span>
            <input type="checkbox" data-testid={props?.dataTestId} disabled={props?.disabled} {...props} />
            {svg}
          </span>
        </span>
        {props?.label && props.label}
      </label>
    </Style>
  );
};

export default memo(Checkbox);
