import React from 'react';
import styled from 'styled-components';
import { variant, VariantArgs, color, typography, space } from 'styled-system';
import { defaults } from '../../../SubatomicParticles';
import { H1StyledProps } from './types';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      fontFamily: 'OpenSans Bold',
      fontSize: '18px',
    },
  },
} as VariantArgs);

export const H1: React.FC<H1StyledProps> = styled.h1`
  ${defaults}
  ${variants}
    ${color}
    ${typography}
    ${space}
`;

export default H1;
