export default {
  primary: {
    default: '#265672',
    hover: '#17384b',
  },
  highContrast: 'rgba(0, 0, 0, 0.85)',
  border: 'rgba(117, 117, 117, 0.1)',
  newBorder: '#00AFB1',
  borderShadow: 'rgba(255, 255, 255, 0.2)',
  success: { default: '#FDDB70', hover: '#e2c366' },
  successShadow: 'rgba(85, 85, 85, 0.2)',
  hovers: ['#f5f7f7'],
  submitText: 'rgba(0, 0, 0, 0.65)',
  signLine: '#C4C4C4',
  overlay: 'rgba(0, 0, 0, 0.3)',
};
