import React from 'react';
import styled from 'styled-components';
import { variant, VariantArgs, color, typography, space, system } from 'styled-system';
import { defaults } from '../../../SubatomicParticles';
import { PStyledProps } from './types';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      fontFamily: 'OpenSans SemiBold',
      color: 'highContrast',
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '19px',
    },
  },
} as VariantArgs);

export const P: React.FC<PStyledProps> = styled.p`
  ${defaults}
  ${variants}
    ${color}
    ${typography}
    ${space}
    ${system({
    textTransform: true,
  })}
`;

export default P;
