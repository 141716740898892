import React, { memo } from 'react';
import styled from 'styled-components';
import { color, typography } from 'styled-system';
import { defaults } from '../../../SubatomicParticles';
import { space } from '../../sizes';
import { SpanStyledProps } from './types';

export const Span: React.FC<SpanStyledProps> = styled.span<SpanStyledProps>`
  ${defaults};
  ${(props) => props.css ?? ''};
  ${color};
  ${typography};
  ${space}
`;

export default memo(Span);
