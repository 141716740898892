import React from 'react';
import Link from '../ds/atoms/Link';
import { Props } from './types';

const HiperLink: React.FC<Props> = ({ text, to, onClick }: Props) => {
  return (
    <Link to={to || '#'} styling="base" onClick={onClick}>
      {text}
    </Link>
  );
};

export default HiperLink;
