/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentStatus
 */
export interface DocumentStatus {
    /**
     * Document status
     * @type {string}
     * @memberof DocumentStatus
     */
    status: DocumentStatusStatusEnum;
    /**
     * Date of the signature
     * @type {Date}
     * @memberof DocumentStatus
     */
    signatureDate?: Date;
}

/**
* @export
* @enum {string}
*/
export enum DocumentStatusStatusEnum {
    Signed = 'SIGNED',
    Pending = 'PENDING',
    Expired = 'EXPIRED'
}

export function DocumentStatusFromJSON(json: any): DocumentStatus {
    return DocumentStatusFromJSONTyped(json, false);
}

export function DocumentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
    };
}

export function DocumentStatusToJSON(value?: DocumentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString()),
    };
}


