/* tslint:disable */
/* eslint-disable */
/**
 * Consiga Assinatura
 * API para sistema de assinatura deigital da ConsigaMais
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignDocumentMetadata,
    SignDocumentMetadataFromJSON,
    SignDocumentMetadataFromJSONTyped,
    SignDocumentMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface HandmadeSignObject
 */
export interface HandmadeSignObject {
    /**
     * Assinatura manuscrita do usuário em Base64
     * @type {string}
     * @memberof HandmadeSignObject
     */
    handmadeSign: string;
    /**
     * Ao criar um documento é gerada uma URL única com um prazo de expiração. Esta url é passada com parâmetro para interagir com o documento
     * @type {string}
     * @memberof HandmadeSignObject
     */
    shortUrl: string;
    /**
     * 
     * @type {SignDocumentMetadata}
     * @memberof HandmadeSignObject
     */
    signDocumentMetadata: SignDocumentMetadata;
     /**
     * Tipo do documento
     * @type {string}
     * @memberof HandmadeSignObject
     */
    documentType: string;
}

export function HandmadeSignObjectFromJSON(json: any): HandmadeSignObject {
    return HandmadeSignObjectFromJSONTyped(json, false);
}

export function HandmadeSignObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandmadeSignObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'handmadeSign': json['handmadeSign'],
        'shortUrl': json['shortUrl'],
        'signDocumentMetadata': SignDocumentMetadataFromJSON(json['signDocumentMetadata']),
        'documentType': 'PJ',
    };
}

export function HandmadeSignObjectToJSON(value?: HandmadeSignObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'handmadeSign': value.handmadeSign,
        'shortUrl': value.shortUrl,
        'signDocumentMetadata': SignDocumentMetadataToJSON(value.signDocumentMetadata),
        'documentType': 'PJ',
    };
}


