import React, { useRef, useState } from 'react';
import { Props } from './types';
import Header from '../../components/Header';
import { Box } from '../../components/ds/SubatomicParticles';
import { H1 } from '../../components/ds/theme/typography';
import SubmitButton from '../../components/SubmitButton';
import HiperLink from '../../components/HiperLink';
import SignCanvas from './SignCanvas';
import { useParams, useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { signDocumentWithHandmadeSign } from '../TokenSign/actions';
import Swal from 'sweetalert2';
import { ShortUrlObject } from '../../state';
import ReactSignatureCanvas from 'react-signature-canvas';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import { GeolocatedProps, geolocated } from 'react-geolocated';
import './sign.css';

const Sign: React.FC<Props> = () => {
  const canvasRef = useRef<ReactSignatureCanvas | null>(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { shortUrl } = useParams<ShortUrlObject>();

  const sAlert = withReactContent(Swal);

  const getCanvasImage = () => canvasRef.current?.getTrimmedCanvas().toDataURL();

  const clearCanvasImage = () => canvasRef.current?.clear();

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const params = new URLSearchParams(document.location.search.substring(1));
      let latitude: string | number | null = params.get('latitude');
      let longitude: string | number | null = params.get('longitude');

      if (latitude != null && longitude != null) {
        latitude = parseFloat(latitude);
        longitude = parseFloat(longitude);
      } else {
        latitude = parseFloat('-19.940175099999998');
        longitude = parseFloat('43.93413150000001');
      }

      const signDocumentMetadata = {
        dthrAssinatura: new Date(),
        geolocalizacao: JSON.stringify({
          latitude,
          longitude,
        }),
        documentType: 'PJ',
      };
      const handmadeSign = getCanvasImage()?.split(',')[1];
      if (canvasRef.current?.isEmpty() || !handmadeSign) {
        throw new Error('Empty canvas');
      }
      const documentType = 'PJ';
      await signDocumentWithHandmadeSign({
        handmadeSign: { handmadeSign, shortUrl, signDocumentMetadata, documentType },
      });
      clearCanvasImage();
      setIsLoading(false);
      sAlert
        .fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Documento assinado com sucesso!',
        })
        .then(() => {
          history.push(`/${shortUrl}/signedDocument`);
        });
    } catch (e) {
      setIsLoading(false);
      sAlert.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Algo deu errado',
      });
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box className="header-box">
        <Header />
      </Box>
      <div className="sign-container">
        <Box styling="row" mt="4vh">
          <H1 color="primary.default" styling="base">
            Assine na linha abaixo
          </H1>
        </Box>
        <Box styling="row" mt="3vh">
          <SignCanvas canvasRef={canvasRef} />
        </Box>
        <Box styling="row" mt="8vh">
          <Box styling="row">
            <SubmitButton disabled={false} text="ÓTIMO" onClick={onSubmit} />
          </Box>
        </Box>
        <Box styling="row" mt="4vh">
          <Box styling="row">
            <HiperLink to="" text="fazer outra" onClick={clearCanvasImage} />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default geolocated()(Sign);
