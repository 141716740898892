/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { default as OtpInputComponent } from 'react-otp-input';
import { useTheme } from '../../../components/ds/hooks';

import { Props } from './types';

const OtpInput: React.FC<Props> = ({ value, onChange }: Props) => {
  const theme = useTheme();
  const inputStyle = {
    height: '50px',
    width: '50px',
    margin: '5px',
    border: `1px solid ${theme.colors.signLine}`,
    borderRadius: '3px',
    boxShadow: `0px 1px 2px 0px ${theme.colors.borderShadow}`,
    fontSize: '16px',
    fontFamily: 'OpenSans Regular',
    color: theme.colors.primary.default,
  };

  return (
    <>
      <OtpInputComponent inputStyle={inputStyle} numInputs={6} value={value} onChange={onChange} shouldAutoFocus />
    </>
  );
};

export default OtpInput;
