import React from 'react';
import { Button } from '../ds/SubatomicParticles';
import { Span } from '../ds/theme/typography/Span/Span';

import { Props } from './types';

const SubmitButton: React.FC<Props> = ({ text, disabled, onClick }: Props) => {
  return (
    <Button
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
      styling={disabled ? 'disabled' : 'base'}
      type="button"
      width="210px"
      height="45px"
      py="15px"
      px="10px"
    >
      <Span fontFamily="OpenSans Bold" lineHeight="19px" fontSize="14px" color="submitText">
        {text}
      </Span>
    </Button>
  );
};

export default SubmitButton;
